import dayjs from 'dayjs';
import {
  AppointmentForUI,
  createNeverNullDayjs,
  hasProperty,
} from '@chiroup/core';
import { SelectedLocationFullType } from '../../../contexts/me.context';

//for a reason I've yet to fully grasp the deepClone below was required for dates in room appointments

export const deepClone = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  if (dayjs.isDayjs(obj)) {
    return obj.clone();
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item));
  }
  const clonedObj: any = {};
  for (const key in obj) {
    if (hasProperty(obj, key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }
  return clonedObj;
};

export const findAppointmentInCache = (
  listCache: any[],
  day: string,
  id: string,
) => {
  let cacheWithAppointment = -1;
  let previousDay: string | null = null;
  let previousClinicianId: string | null = null;
  let cacheWithNewDay = -1;
  let previousRoomId: string | null = null;

  if (listCache) {
    for (let i = 0; i < listCache.length; i++) {
      const item = listCache[i];
      const data = item.state.data as any;
      const dataAppointments = data || {};
      const days = Object.keys(dataAppointments || {});
      if (days.includes(day)) {
        cacheWithNewDay = i;
      }
      const itemEntries = Object.entries(dataAppointments || {}) as any;
      for (let j = 0; j < itemEntries.length; j++) {
        const [dayKey, value] = itemEntries[j];
        const valueEntries = Object.entries(value);
        for (let k = 0; k < valueEntries.length; k++) {
          const [key, innerItem]: [string, any] = valueEntries[k];
          const appointments = innerItem?.appointments || [];
          if (appointments.length > 0) {
            for (let l = 0; l < appointments.length; l++) {
              const appItem = appointments[l];
              if (appItem.id === id) {
                cacheWithAppointment = i;
                previousDay = dayKey;

                // Use 'isRoom' flag to distinguish between clinician and room
                if (innerItem.isRoom) {
                  previousRoomId = key;
                } else {
                  previousClinicianId = key;
                }
              }
            }
          }
        }
      }
    }
  }

  return {
    cacheWithAppointment,
    previousDay,
    previousClinicianId,
    cacheWithNewDay,
    previousRoomId,
  };
};

export const updateCacheWithAppointment = (
  queryClient: any,
  listCache: any[],
  cacheIndex: number,
  item: any,
  previousDay: string,
  previousClinicianId: string | null,
  previousRoomId: string | null,
  day: string,
) => {
  queryClient.setQueryData(listCache[cacheIndex].queryKey, (prev: any) => {
    const newValue = deepClone(prev);
    // Remove appointment from previous clinician
    if (previousClinicianId) {
      const prevClinicianAppointments =
        newValue[previousDay][previousClinicianId]?.appointments || [];
      newValue[previousDay][previousClinicianId].appointments =
        prevClinicianAppointments.filter((appt: any) => appt.id !== item.id);
    }

    // Remove appointment from previous room
    if (previousRoomId) {
      const prevRoomAppointments =
        newValue[previousDay][previousRoomId]?.appointments || [];
      newValue[previousDay][previousRoomId].appointments =
        prevRoomAppointments.filter((appt: any) => appt.id !== item.id);
    }
    // Add appointment to new clinician
    const clinicianId = item.clinicianId;
    if (!newValue[day][clinicianId]) {
      newValue[day][clinicianId] = { appointments: [], isRoom: false };
    }
    const clinicianAppointments = newValue[day][clinicianId].appointments || [];
    clinicianAppointments.push(item);
    newValue[day][clinicianId].appointments = clinicianAppointments;

    // Add appointment to new room
    const roomId = item.roomId;
    if (roomId) {
      if (!newValue[day][roomId]) {
        newValue[day][roomId] = { appointments: [], isRoom: true };
      }
      const roomAppointments = newValue[day][roomId].appointments || [];
      roomAppointments.push(item);
      newValue[day][roomId].appointments = roomAppointments;
    }

    return newValue;
  });
};

export const processAppointmentOrSlots = (
  appointment: AppointmentForUI,
  params: {
    queryClient: any;
    selectedLocationFull: SelectedLocationFullType;
    queryKey: any;
    setIsSubmitting?: (value: boolean) => void;
    setIsChangingStatus?: (value: boolean) => void;
  },
) => {
  const {
    queryClient,
    selectedLocationFull,
    queryKey,
    setIsSubmitting,
    setIsChangingStatus,
  } = params;

  const timezone = selectedLocationFull.timezone as string;
  const listCache = queryClient.getQueryCache().findAll(queryKey);

  const items =
    appointment.slots && appointment.slots.length > 0
      ? appointment.slots
      : [appointment];

  items.forEach((item) => {
    const dayjsStartTime = createNeverNullDayjs({
      datetime: item.startTime,
      timezone,
    });
    const day = dayjsStartTime.format('YYYY-MM-DD');

    const {
      cacheWithAppointment,
      previousDay,
      previousClinicianId,
      cacheWithNewDay,
      previousRoomId,
    } = findAppointmentInCache(listCache, day, item.id);

    if (cacheWithAppointment > -1 && previousDay) {
      if (setIsSubmitting) setIsSubmitting(true);
      updateCacheWithAppointment(
        queryClient,
        listCache,
        cacheWithAppointment,
        item,
        previousDay,
        previousClinicianId,
        previousRoomId,
        day,
      );
    }

    if (cacheWithNewDay > -1 && cacheWithNewDay !== cacheWithAppointment) {
      queryClient.setQueryData(
        listCache[cacheWithNewDay].queryKey,
        (prev: any) => {
          const newValue = deepClone(prev);
          // Add to clinician
          const clinicianId = item.clinicianId;
          if (!newValue[day][clinicianId]) {
            newValue[day][clinicianId] = { appointments: [], isRoom: false };
          }
          const clinicianAppointments =
            newValue[day][clinicianId].appointments || [];
          clinicianAppointments.push(item);
          newValue[day][clinicianId].appointments = clinicianAppointments;
          const roomId = item.roomId;
          if (roomId) {
            if (!newValue[day][roomId]) {
              newValue[day][roomId] = { appointments: [], isRoom: true };
            }
            const roomAppointments = newValue[day][roomId].appointments || [];
            roomAppointments.push(item);
            newValue[day][roomId].appointments = roomAppointments;
          }

          return newValue;
        },
      );
    }
    queryClient.setQueryData(['appointments', 'detail', item.id], item);
    if (setIsSubmitting) setIsSubmitting(false);
    if (setIsChangingStatus) setIsChangingStatus(false);
  });
};
