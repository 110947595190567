import {
  AppointmentForUI,
  DisciplineTreatment,
  classNames,
  createNeverNullDayjs,
} from '@chiroup/core';
import dayjs, { isDayjs } from 'dayjs';
import React, { useContext, useMemo } from 'react';
import ScheduleEvent, { minutesToPixels } from './ScheduleEvent';
import ScheduleTimeSlot from './ScheduleTimeSlot';
import ScheduleEventSlot from './ScheduleEventSlot';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AppointmentsContext } from '../../contexts/appointments.context';

dayjs.extend(utc);
dayjs.extend(tz);

const addDetailsToAppointments = (
  appointments: AppointmentForUI[],
  hourOffset: number,
  zoom: number,
  isRoom: boolean,
) => {
  return appointments
    .map((appointment) => {
      const start = isDayjs(appointment.startTime)
        ? appointment.startTime
        : createNeverNullDayjs({
            datetime: appointment.startTime,
            timezone: appointment.tz,
          });
      const startHourMinutes = (start.hour() - hourOffset) * 60;
      const startMinutes = start.minute() + startHourMinutes;
      const top = minutesToPixels(startMinutes, zoom);
      const height = minutesToPixels(appointment.duration, zoom) - 1;
      return {
        ...appointment,
        height,
        top: top,
        bottom: top + height,
        width: 100,
        isRoom,
      };
    })
    ?.sort(
      (e1, e2) =>
        e1.top - e2.top || e1.bottom - e2.bottom || e1.height - e2.height,
    );
};

function findFirstAvailableColumn(
  event: AppointmentForUI,
  columns: AppointmentForUI[][],
): number {
  for (let i = 0; i < columns.length; i++) {
    const lastEvent = columns[i][columns[i].length - 1];
    if (!collidesWith(lastEvent, event)) {
      return i;
    }
  }
  return columns.length;
}

function updateEventDimensions(
  event: AppointmentForUI,
  columnWidth: number,
  columnLeft: number,
): void {
  event.width = columnWidth;
  event.left = columnLeft;
}

export function processAppointments(
  appointments: AppointmentForUI[],
  hourOffset = 0,
  zoom: number,
  isRoom: boolean,
): AppointmentForUI[][] {
  let columns: AppointmentForUI[][] = [];
  let completedColumns: AppointmentForUI[][] = [];
  let lastEventEnding: number | null = null;

  const events =
    addDetailsToAppointments(appointments, hourOffset, zoom, isRoom) || [];

  events.forEach((event) => {
    if (lastEventEnding !== null && event.top > lastEventEnding) {
      completedColumns = completedColumns.concat(packEvents(columns));
      columns = [];
      lastEventEnding = null;
    }

    const columnIndex = findFirstAvailableColumn(event, columns);
    if (columns[columnIndex] === undefined) {
      columns[columnIndex] = [event];
    } else {
      columns[columnIndex].push(event);
    }

    if (lastEventEnding === null || event.bottom > lastEventEnding) {
      lastEventEnding = event.bottom;
    }
  });

  if (columns.length > 0) {
    columns = packEvents(columns);
  }
  return [...completedColumns, ...columns];
}

function packEvents(columns: AppointmentForUI[][]) {
  const columnWidth = 100 / columns.length;

  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    const columnLeft = columnWidth * colIndex;
    columns[colIndex].forEach((event) =>
      updateEventDimensions(event, columnWidth, columnLeft),
    );
  }

  return extendEventsToOtherColumnsIfPossible(columns);
}

const extendEventsToOtherColumnsIfPossible = (
  columns: AppointmentForUI[][],
): AppointmentForUI[][] => {
  const columnWidth = 100 / columns.length;
  return columns.map((col, colIndex) => {
    return col.map((event) => {
      let lastColumnWithNoCollisions = colIndex;

      for (let i = colIndex + 1; i < columns.length; i++) {
        let hasCollision = false;

        for (const otherEvent of columns[i]) {
          if (collidesWith(event, otherEvent)) {
            hasCollision = true;
            break;
          }
        }

        if (hasCollision) {
          break;
        } else {
          lastColumnWithNoCollisions = i;
        }
      }

      if (lastColumnWithNoCollisions > colIndex) {
        event.width = columnWidth * (lastColumnWithNoCollisions - colIndex + 1);
      }
      return event;
    });
  });
};

function collidesWith(a: AppointmentForUI, b: AppointmentForUI) {
  if (!a || !b) return false;
  if ((a.bottom || 0) <= (b.top || 0) || (b.bottom || 0) <= (a.top || 0)) {
    return false;
  }
  return true;
}

type Props = {
  appointments: AppointmentForUI[];
  day: string;
  userId: string;
  dragging: boolean;
  width: number;
  availableSlots: AppointmentForUI[] | null;
  selectedTreatment: DisciplineTreatment | null;
  disableEditing?: boolean;
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
  scheduleApptFromDoubleClick: (
    clinician: string,
    startTime: number,
    date: string,
  ) => void;
  available?: { start: number; end: number }[];
  isRoom?: boolean;
};

const ScheduleEventsUser: React.FC<Props> = ({
  appointments,
  day,
  userId,
  dragging,
  width,
  availableSlots,
  selectedTreatment,
  disableEditing,
  minMaxTime,
  scheduleApptFromDoubleClick,
  available,
  isRoom = false,
}) => {
  const { zoom } = useContext(AppointmentsContext);

  const priorDay = useMemo(() => {
    const yesterday = dayjs().subtract(1, 'day');
    const dayDate = dayjs(day);
    return dayDate <= yesterday;
  }, [day]);

  const appointmentsViewData = useMemo(() => {
    return processAppointments(
      appointments,
      minMaxTime.minTime,
      zoom,
      isRoom,
    ).flat();
  }, [appointments, isRoom, minMaxTime.minTime, zoom]);

  const availableSlotsViewData = useMemo(() => {
    if (!availableSlots) return [];
    return processAppointments(
      availableSlots,
      minMaxTime.minTime,
      zoom,
      isRoom,
    ).flat();
  }, [availableSlots, isRoom, minMaxTime.minTime, zoom]);

  const numberOf15MinBlocks = () => {
    const { minTime, maxTime } = minMaxTime;
    return (maxTime - minTime) * 4;
  };

  return (
    <div
      className={classNames(
        'flex flex-col relative print:hidden',
        dragging ? 'pointer-events-none' : '',
      )}
      style={{
        width,
      }}
    >
      {Array.from({ length: numberOf15MinBlocks() }).map((_, rowIndex) => (
        <ScheduleTimeSlot
          key={`row-${rowIndex}`}
          id={`${day}|${userId}|${rowIndex}`}
          day={day}
          userId={userId}
          rowIndex={rowIndex}
          priorDay={priorDay}
          minMaxTime={minMaxTime}
          scheduleApptFromDoubleClick={scheduleApptFromDoubleClick}
          available={available}
          isRoom={isRoom}
        />
      ))}
      {appointmentsViewData?.map((appointment, i) => (
        <ScheduleEvent
          key={isRoom ? 'room-' + appointment.id : appointment.id}
          appointment={appointment}
          day={day}
          userId={userId}
          disabled={disableEditing}
          isRoom={isRoom}
        />
      ))}
      {selectedTreatment &&
        availableSlotsViewData?.map((appointment, i) => (
          <ScheduleEventSlot
            key={i}
            appointment={appointment}
            userId={userId}
            treatment={selectedTreatment}
          />
        ))}
      {/* 
        This is where we need to put the available time slots...
        Probably a new component that takes in the available time for the day, the appointment info (name/duration) and the appointmentsViewData...
        Then the component itself will figure out how to render the available time slots...
      */}
    </div>
  );
};

export default ScheduleEventsUser;
