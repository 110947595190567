import {
  Autocomplete,
  ConfirmModal,
  MakeBrowserWait,
  NotFound,
  OpenClosedStates,
  Select,
  Textarea,
  TimestampDisplay,
  TrivialTooltip,
  getNearest,
  getNearestTime,
} from '@chiroup/components';
import {
  AppointmentForUI,
  ChiroUpAppointmentCommon,
  ChiroUpDayJsCommon,
  ChiroUpJSON,
  CommunicationMethodTypes,
  FeatureFlags,
  FormErrors,
  NUMBER_ANY_HASH,
  Patient,
  RoomStatus,
  SELECT_OPTION_TYPE,
  STRING_BOOLEAN_HASH,
  classNames,
  disableStatusesInPast,
  durationDisplay,
  surveyIds,
} from '@chiroup/core';
import { SubmitReturnType } from '@chiroup/hooks';
import {
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import qs from 'query-string';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Timeout } from 'react-number-format/types/types';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { MeContext } from '../../contexts/me.context';
import { ToastContext } from '../../contexts/toast.context';
import useSurveys from '../../hooks/useSurveys';
import businessRulesService from '../../services/businessRules.service';
import AlertBlock from '../common/AlertBlock';
import Button, { ButtonColors } from '../common/Button';
import IconButton from '../common/IconButton';
import LegacyEhrUser from '../common/icons/LegacyEhrUser';
import PatientCreateModal from '../patients/create/PatientCreateModal';
import usePatients from '../patients/hooks/usePatients';
import useConsents from '../practice-resources/hooks/useConsents';
import { transformPreferences } from '../settings/clinic/ClinicSettingsPatientPreferences';
import { PatientPreferencesForm } from '../settings/clinic/ClinicSettingsPatientPreferencesForm';
import ScheduleAppointmentFormSlot from './ScheduleAppointmentFormSlot';

// import DisclosureButton from '../common/DisclosureButton';
const maxSlots = 7;

type Props = {
  appointment?: AppointmentForUI | null;
  onClose: () => void;
  saveAppointment: ({
    appointment,
    notify,
    removeRecurringId,
    doubleBook,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean;
    removeRecurringId?: boolean;
    doubleBook?: boolean;
  }) => Promise<AppointmentForUI | undefined>;
  timezone: string;
  newAppointment?: boolean;
  disabled?: boolean;
  editingOneOfRecurring?: boolean;
  setEditType?: (type: string) => void;
  setShowForm?: (show: boolean) => void;
  value: Partial<AppointmentForUI>;
  onChange: (key: keyof AppointmentForUI) => (val: any) => void;
  errors: FormErrors;
  isDirty: boolean;
  isSubmitting: boolean;
  setValue: (values: Partial<AppointmentForUI>) => void;
  registerSubmit: (
    fnSubmit: (
      values: Partial<AppointmentForUI>,
    ) => SubmitReturnType<AppointmentForUI, void> | undefined,
    {
      onSuccess,
      onFail,
    }: {
      onSuccess?: ((response: any) => void) | undefined;
      onFail?: ((errors: any) => void) | undefined;
      options?: {
        throwOnNoResult?: boolean;
      };
    },
  ) => (e?: any) => void;
  patchValue: (values: Partial<AppointmentForUI>) => void;
  patient?: Patient;
  treatmentHash: any;
  disciplineOptions: SELECT_OPTION_TYPE[];
  startTimeInterval?: number;
  dontNavigate?: boolean;
  addNewSlot: () => void;
  deleteMySlot: (slot: number, options: any) => void;
  queryParams?: any;
  updateMySlot: ({
    ord,
    key,
    value,
  }: {
    ord: number;
    key: string;
    value: any;
  }) => void;
  markAsDirty: () => void;
  formEditState?: boolean | null;
  formEditStateCallback?: (() => void) | null;
  doubleBookErrorMessage?: RoomStatus[];
  setDoubleBookErrorMessage?: React.Dispatch<
    React.SetStateAction<RoomStatus[]>
  >;
  disableButtonGroup?: boolean;
};

const ScheduleAppointmentForm: React.FC<Props> = ({
  appointment,
  onClose,
  saveAppointment,
  timezone,
  newAppointment = false,
  disabled = false,
  editingOneOfRecurring = false,
  setEditType,
  setShowForm,
  onChange,
  errors,
  value,
  isDirty,
  isSubmitting,
  setValue,
  registerSubmit,
  patchValue,
  patient,
  startTimeInterval = 15,
  treatmentHash,
  disciplineOptions,
  dontNavigate = false,
  addNewSlot,
  updateMySlot,
  deleteMySlot,
  queryParams = {},
  markAsDirty,
  formEditState,
  formEditStateCallback,
  doubleBookErrorMessage,
  setDoubleBookErrorMessage,
  disableButtonGroup = false,
}) => {
  const [newPatientModalState, setNewPatientModalState] =
      useState<OpenClosedStates>(OpenClosedStates.Closed),
    queryClient = useQueryClient();
  const [localErrors, setLocalErrors] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [doubleBooking, setDoubleBooking] = useState(false);
  useEffect(() => {
    if (typeof formEditState === 'boolean' && !!formEditStateCallback) {
      setIsEditing(formEditState);
      formEditStateCallback();
    }
  }, [formEditState, formEditStateCallback]);

  const { me, hasAccess, selectedLocationFull } = useContext(MeContext);
  const [businessRuleIssues, setBusinessRuleIssues] = useState<any[]>([]);

  const location = useLocation();
  const { search } = location;
  const [okayToSave] = useState(true);
  const [cancelClone, setCancelClone] = useState<AppointmentForUI>(
    {} as AppointmentForUI,
  );
  const { data: surveys } = useSurveys();
  const { data: consents } = useConsents();
  const navigate = useNavigate();
  const [requestInfoItems, setRequestInfoItems] = useState<any[]>();
  const [createdNewPatient, setCreatedNewPatient] = useState(false);
  const newPatientOpen = () => {
    setNewPatientModalState(OpenClosedStates.Open);
  };

  const newPatientClose = (patient: any) => {
    setNewPatientModalState(OpenClosedStates.Closed);
    if (patient) {
      queryClient.setQueryData(['patients', searchQuery], (old: any) => {
        return {
          ...old,
          pages: old.pages.map((page: any) => {
            return {
              ...page,
              data: [...page.data, patient],
            };
          }),
        };
      });
      patchValue({ patientId: patient.ID });
      setCreatedNewPatient(true);
    }
  };

  const {
    data,
    isFetching: isFetchingPatients,
    fetchNextPage,
    hasNextPage,
    onSearch,
    searchQuery,
    setSearchQuery,
  } = usePatients({ showDeceased: false, skipEmptySearch: true });
  const { createToast } = useContext(ToastContext);

  useEffect(() => {
    return () => {
      setCreatedNewPatient(false);
    };
  }, []);

  useEffect(() => {
    if (appointment) {
      patchValue(appointment);
    } else {
      const {
        treatment,
        clinician,
        startTime,
        startDate,
        duration,
        patient,
        patientName,
        slots,
      } = qs.parse(search) as any;

      const decodedSlots = JSON.parse(decodeURIComponent(slots || '[]'));
      const start = dayjs().format('YYYY-MM-DD');

      const formattedSlots = decodedSlots?.map((slot: any) => {
        const timeOfDay = dayjs
          .tz(slot.startTime, selectedLocationFull.timezone)
          .format('HH:mm:ss');
        const newStartTime = dayjs.tz(
          `${start}T${timeOfDay}`,
          selectedLocationFull.timezone,
        );
        return {
          duration: slot?.duration || 15,
          clinicianId: slot?.clinicianId || '',
          treatmentId: slot.treatmentId || -1,
          startTime: newStartTime,
          roomId: slot.roomId || '',
        };
      });

      const apptSettings = me?.selectedClinic?.settings?.find(
        (setting) => setting.setting === 'Appointment Settings',
      );
      if (patientName && patientName?.length) {
        setSearchQuery(patientName);
      }
      patchValue({
        patientId: patient,
        treatmentId: treatment
          ? Number(treatment)
          : apptSettings?.jsonValue?.appointmentDefaultTreatment,
        clinicianId: clinician,
        startTime: dayjs.tz(
          startTime
            ? Number(startTime)
            : startDate
              ? getNearestTime(startDate)
              : getNearest(timezone),
          timezone,
        ),
        duration: duration
          ? Number(duration)
          : apptSettings?.jsonValue?.appointmentDefaultDuration,
      });
      if (formattedSlots?.length) {
        patchValue({ slots: formattedSlots });
      }
    }
  }, [
    appointment,
    patchValue,
    search,
    me?.selectedClinic?.settings,
    timezone,
    setSearchQuery,
    selectedLocationFull.timezone,
  ]);

  useEffect(() => {
    if (patient) {
      if (!qs.parse(search).clinician && patient?.primaryClinician) {
        patchValue({
          clinicianId: patient?.primaryClinician,
        });
      }
    }
  }, [patient, patchValue, setSearchQuery, search]);

  useEffect(() => {
    if (patient) {
      setSearchQuery(`${patient?.lname}, ${patient?.fname}`);
      patchValue({
        patientId: patient?.ID,
      });
    }
  }, [patient, patchValue, setSearchQuery, search]);

  const patientInfo = useMemo(() => {
    const patientInfoArr = [
      { name: 'Basic intake group', group: 'Groups', id: 'basicIntakeGroup' },
      {
        name: 'Detailed intake group',
        group: 'Groups',
        id: 'detailedIntakeGroup',
      },
      {
        name: 'Photo ID',
        group: 'Intake & history surveys',
        id: 'requestPhotoId',
      },
      {
        name: 'Patient demographics',
        group: 'Intake & history surveys',
        id: 'requestInfo',
      },
    ];

    if (hasAccess(FeatureFlags.billingInsurance)) {
      patientInfoArr.push({
        name: 'Insurance',
        group: 'Intake & history surveys',
        id: 'requestInsurance',
      });
    } else {
      patientInfoArr.push({
        name: 'Insurance card',
        group: 'Intake & history surveys',
        id: 'requestInsuranceCard',
      });
    }

    return patientInfoArr;
  }, [hasAccess]);

  useEffect(() => {
    if (surveys && !requestInfoItems?.length) {
      const hiddenSurveys: string[] =
        me?.selectedClinic?.settings?.find(
          (clinic) => clinic.setting === 'Hidden surveys',
        )?.jsonValue || [];

      const filteredData: {
        name: string;
        group: string;
        id: string;
      }[] =
        surveys?.data?.filter((item) => !hiddenSurveys?.includes(item.id)) ||
        [];

      let items = [...(patientInfo || []), ...(filteredData || [])];
      if (consents?.length) {
        items = [
          ...items,
          ...(consents?.map((consent) => ({
            name: consent.name,
            group: 'Consents & Acknowledgement surveys',
            id: `consent::${consent.url}`,
          })) || []),
        ];
      }
      items.sort((a, b) => {
        if (a.group === 'Groups' && b.group !== 'Groups') {
          return -1;
        } else if (a.group !== 'Groups' && b.group === 'Groups') {
          return 1;
        }
        const groupComparison = a.group.localeCompare(b.group);
        if (groupComparison !== 0) {
          return groupComparison;
        }
        return a.name.localeCompare(b.name);
      });
      setRequestInfoItems(items);
    }
  }, [
    surveys,
    consents,
    me?.selectedClinic?.settings,
    patientInfo,
    requestInfoItems,
  ]);

  const flatPatients = useMemo(() => {
    return data?.pages?.map((page) => page?.data).flat() || [];
  }, [data]);

  const patientOptions = useMemo(() => {
    return flatPatients?.map((pt) => ({
      text: (
        <div className="flex">
          <div className="flex flex-col leading-4">
            <span>
              {pt.lname}, {pt.fname}
            </span>
            <span className="text-xs opacity-50">
              {pt.dateOfBirth
                ? ` ${dayjs(pt.dateOfBirth).format('MM/DD/YYYY')}`
                : ''}
            </span>
          </div>
          {!!pt?.legacyEHRId && <LegacyEhrUser />}
        </div>
      ),
      value: pt.ID,
    }));
  }, [flatPatients]);

  const clearBusinessRuleIssues = useCallback(() => {
    setBusinessRuleIssues([]);
  }, [setBusinessRuleIssues]);

  const onSubmit = async (
    values: Partial<AppointmentForUI>,
    doubleBook?: boolean,
  ) => {
    setBusinessRuleIssues([]);
    console.log('......on-submit is firing...', values, disabled);
    if (disabled) return;
    const issues = businessRulesService.sanityCheckAppointment({
      appointment: value as AppointmentForUI,
      primaryDisciplineOptions,
      tz: selectedLocationFull?.timezone,
      frontend: true,
    });

    if (issues.length) {
      setBusinessRuleIssues(issues);
      setTimeout(() => {
        document?.getElementById?.('alertRef')?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
      return;
    }

    if (
      value.policies?.length &&
      !value.policies?.every((policy) => policy.billingPriority)
    ) {
      return;
    }
    if (appointment?.recurringAppointmentId) {
      return saveAppointment({
        appointment: values,
        removeRecurringId: true,
      });
    }

    return saveAppointment({
      appointment: values,
      doubleBook,
    })
      .then((resp: AppointmentForUI | undefined) => {
        console.log({ saveOnAppointment: resp });
        if (resp && resp?.id) {
          patchValue(resp as AppointmentForUI);
        }
        return resp;
      })
      .catch((e: any) => {
        console.error(e);
        setLocalErrors(e?.message || 'Error saving appointment');
        throw e;
      });
  };

  const onSubmitNotify = async (values: Partial<AppointmentForUI>) => {
    if (disabled) return;
    return saveAppointment({
      appointment: values,
      notify: true,
    });
  };

  const onSuccess = (val: any) => {
    console.log('......on-success is firing...', val);
    setIsEditing(false);
    const searchParams = qs.parse(search);
    if (newAppointment) {
      if (val.id) {
        const params =
          searchParams.startDate && searchParams.endDate
            ? `/schedule?open=${val.id}&startDate=${searchParams.startDate}&endDate=${searchParams.endDate}`
            : `/schedule?open=${val.id}`;
        if (!dontNavigate) {
          navigate(`${params}`);
        } else {
          //Coming from appointment tab in patient
          onClose();
        }
      }
    }
  };

  const onFail = (e: any) => {
    console.log('......on-fail is firing...', e);
    //  Nothing is done here as there is another catch that handles errors.
  };

  const disabledEvent = useMemo(() => {
    if (!appointment) return false;
    const inPast = dayjs(appointment.startTime) < dayjs();
    const disabledEvent =
      !!appointment.recurringAppointmentId ||
      (inPast && disableStatusesInPast.includes(appointment.status));
    return disabledEvent;
  }, [appointment]);

  const customIntakes = useMemo(() => {
    const customSettings = me.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Custom intakes',
    );
    return {
      basicIntakeGroup: customSettings?.jsonValue?.basicIntakeSurveys,
      detailedIntakeGroup: customSettings?.jsonValue?.detailedIntakeSurveys,
    };
  }, [me.selectedClinic?.settings]);

  const onChangeSurveys = useCallback(
    (val: string[]) => {
      if (val?.includes('basicIntakeGroup')) {
        if (customIntakes?.basicIntakeGroup) {
          val = val.concat(customIntakes.basicIntakeGroup);
        } else {
          val = val.concat([
            surveyIds.healthHistory,
            surveyIds.reviewOfSystems,
          ]);
        }
      }
      if (val?.includes('detailedIntakeGroup')) {
        if (customIntakes?.detailedIntakeGroup) {
          val = val.concat(customIntakes.detailedIntakeGroup);
        } else {
          val = val.concat([
            surveyIds.healthHistory,
            surveyIds.reviewOfSystems,
            surveyIds.lifestyleHistory,
            surveyIds.familyHistory,
            surveyIds.medicationHistory,
            surveyIds.surgicalHistory,
            'requestInfo',
          ]);
        }
      }
      val = val.filter(
        (surveyId) =>
          surveyId !== 'basicIntakeGroup' && surveyId !== 'detailedIntakeGroup',
      );
      // Remove duplicates
      val = Array.from(new Set(val));
      const availableSurveyIds =
        requestInfoItems?.map((survey) => survey.id) ?? [];
      // Filter val to include only available survey IDs
      val = val.filter((surveyId) => availableSurveyIds.includes(surveyId));

      onChange('surveys')(val);
    },
    [customIntakes, onChange, requestInfoItems],
  );

  const surveyOptions = useMemo(() => {
    return requestInfoItems?.map((survey) => ({
      text: survey.name,
      value: survey.id,
      group: survey.group,
    }));
  }, [requestInfoItems]);

  const patientPreferencesTranslations: { [key: string]: string } =
    useMemo(() => {
      return {
        allergySurvey: surveyIds.allergy,
        'Back Bournemouth Questionnaire': surveyIds.backBournemouth,
        lefs: surveyIds.lefs,
        'Neck Bournemouth Questionnaire': surveyIds.neckBournemouth,
        ndi: surveyIds.ndi,
        quickDash: surveyIds.dash,
        oswestry: surveyIds.oswestry,
        ccs: surveyIds.ccs,
        familyHistory: surveyIds.familyHistory,
        healthHistory: surveyIds.healthHistory,
        lifestyleHistory: surveyIds.lifestyleHistory,
        medicationHistory: surveyIds.medicationHistory,
        reviewOfSystems: surveyIds.reviewOfSystems,
        surgicalHistory: surveyIds.surgicalHistory,
        photoID: 'requestPhotoId',
        insurance: hasAccess(FeatureFlags.billingInsurance)
          ? 'requestInsurance'
          : 'requestInsuranceCard',
        newInsurance: 'requestInsurance',
        demographics: 'requestInfo',
      };
    }, [hasAccess]);

  useEffect(() => {
    if (me?.selectedClinic?.settings && createdNewPatient) {
      console.log({
        settings: me.selectedClinic?.settings,
        patientPreferencesTranslations,
      });
      const transformedSettings = Array.isArray(me.selectedClinic?.settings)
        ? transformPreferences(me.selectedClinic?.settings)
        : me.selectedClinic?.settings || {};
      console.log({ transformedSettings });
      const newPatientVals = Object.keys(patientPreferencesTranslations).reduce(
        (
          obj: {
            ccsFollowups?: string[];
            surveys?: string[];
            prefers?: CommunicationMethodTypes;
            requestInfo?: boolean;
            requestInsuranceCard?: boolean;
            requestInsurance?: boolean;
            requestPhotoId?: boolean;
          },
          setting: any,
        ) => {
          if (
            ['demographics', 'photoID', 'insurance'].includes(setting) &&
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            obj.surveys?.push(patientPreferencesTranslations[setting]);
          } else if (
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            const translation = patientPreferencesTranslations[setting];
            if (
              setting === 'ndi' ||
              setting === 'lefs' ||
              setting === 'quickDash' ||
              setting === 'oswestry'
            ) {
              obj.ccsFollowups?.push(translation);
            } else if (
              (translation && !hasAccess(FeatureFlags.ehr)) ||
              (translation &&
                translation !== surveyIds.ccs &&
                hasAccess(FeatureFlags.ehr))
            ) {
              obj.surveys?.push(translation);
            }
          }
          return obj;
        },
        { surveys: [] },
      );

      patchValue(newPatientVals);
    }
  }, [
    hasAccess,
    me.selectedClinic?.settings,
    patchValue,
    patientPreferencesTranslations,
    createdNewPatient,
  ]);

  setTimeout(function () {
    const el = document.getElementById('chmln-dom');
    if (el) {
      el.style.display = 'none';
      // https://help.chiroup.com/send-groups-of-surveys
      // Customize surveys in the basic and/or detailed groups. Click here to learn how.
    }
  }, 3000);

  const slotOptions = useMemo(() => {
    const clinicians =
      me.selectedClinic?.clinicians?.map((clinician) => ({
        text: clinician.name,
        value: clinician.ID,
      })) ?? [];
    clinicians.unshift({ text: '- none -', value: '-1' });

    return {
      clinicians,
      treatments: treatmentHash?.options as SELECT_OPTION_TYPE[],
      durations:
        treatmentHash?.durations?.map((duration: number) => ({
          value: duration,
          text: `${duration}`,
        })) || [],
      rooms: treatmentHash?.roomSelectComponents || [],
    };
  }, [
    me.selectedClinic?.clinicians,
    treatmentHash?.durations,
    treatmentHash?.options,
    treatmentHash?.roomSelectComponents,
  ]);

  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    0: { visible: true },
  });

  useEffect(() => {
    if (editingOneOfRecurring) {
      setCancelClone(ChiroUpJSON.clone(appointment));
      setIsEditing(true);
    }
  }, [appointment, editingOneOfRecurring, slotContext, value, value.startTime]);

  const atSlotLimit = useMemo(() => {
    return value?.slots && value?.slots?.length >= maxSlots;
  }, [value?.slots]);

  const deleteMySlotAndUpdateContext = useCallback(
    (slot: number, options: any) => {
      console.log({
        deleteMySlotAndUpdateContext: slot,
        there: typeof deleteMySlot,
      });
      const arraySlots = Object.keys(slotContext).map(
        (key) => slotContext[Number(key)],
      );
      arraySlots.splice(slot, 1);
      const newContext = arraySlots.reduce((acc, curr, index) => {
        acc[index] = curr;
        return acc;
      }, {});
      setSlotContext(newContext);
      deleteMySlot(slot, options);

      // forceUpdate();
    },
    [deleteMySlot, slotContext],
  );

  const primaryClinicianOptions = useMemo(() => {
    if (!value?.slots) return [];
    const usedClinicians = value.slots.reduce((a, slot) => {
      a[slot.clinicianId] = true;
      return a;
    }, {} as STRING_BOOLEAN_HASH);
    return slotOptions?.clinicians.filter(
      (option) => usedClinicians[option.value] && option.value !== '-1',
    );
  }, [slotOptions?.clinicians, value.slots]);

  const primaryTreatmentOptions = useMemo(() => {
    if (!value?.slots) return [];
    const usedTreatmentIds = value.slots.reduce((a, slot) => {
      a[slot.treatmentId] = true;
      return a;
    }, {} as NUMBER_ANY_HASH);
    return slotOptions?.treatments?.filter(
      (option) =>
        usedTreatmentIds[Number(option.value)] && option.value !== '-1',
    );
  }, [slotOptions?.treatments, value.slots]);

  const primaryDisciplineOptions = useMemo(() => {
    if (!value?.slots) return [];
    const usedTreatmentIds = value.slots.map((slot) => {
      return slot.treatmentId;
    }, [] as number[]);
    return disciplineOptions?.filter((option: any) => {
      let res = false,
        x = 0;
      while (x < usedTreatmentIds.length && !res) {
        if (option.treatments[usedTreatmentIds[x]]) res = true;
        x++;
      }
      return res;
    });
  }, [disciplineOptions, value.slots]);

  useEffect(() => {
    if (!value?.disciplineId && primaryDisciplineOptions?.length) {
      onChange('disciplineId')(primaryDisciplineOptions[0]?.value);
    }
  }, [primaryDisciplineOptions, onChange, value?.disciplineId]);

  const dateInPast = useMemo(() => {
    try {
      const dn = new Date(),
        intNow = new Date(
          `${dn.getFullYear()}-${dn.getMonth() + 1}-${dn.getDate()}`,
        ).getTime(),
        s = `${value?.startTime}`,
        intSelected = new Date(Number(s)).getTime();
      // console.log({ dateInPast: { s, intSelected, intNow, value } });
      return intSelected < intNow;
    } catch (e: any) {
      console.log(e);
      return false;
    }
  }, [value]);

  const [showTips, setShowTips] = useState<boolean>(false);

  const doubleBookConfirm = async () => {
    try {
      const searchParams = qs.parse(search);
      setDoubleBooking(true);
      const res = await saveAppointment({
        appointment: value || {},
        doubleBook: true,
      });

      const params =
        searchParams.startDate && searchParams.endDate
          ? `/schedule?open=${res?.id}&startDate=${searchParams.startDate}&endDate=${searchParams.endDate}`
          : `/schedule?open=${res?.id}`;
      navigate(`${params}`);
    } catch (e) {
      console.error(e);
    } finally {
      setDoubleBookErrorMessage?.([]);
      setDoubleBooking(false);
      setIsEditing(false);
    }
  };

  return (
    <div className="relative">
      <div
        className={classNames(
          'p-4 border border-gray-300 rounded-lg dark:border-darkGray-600 dark:bg-darkGray-900 dark:text-gray-300',
          disabled
            ? 'cursor-not-allowed'
            : disabledEvent
              ? 'cursor-not-allowed'
              : !isEditing
                ? 'cursor-pointer hover:bg-primary-50 hover:border-gray-300 dark:hover:bg-darkGray-800 '
                : ' ',
          disabledEvent ? 'bg-orange-50 ' : 'bg-gray-50',
        )}
        title={disabledEvent ? ChiroUpAppointmentCommon.cannotModify : ''}
        onClick={(e: any) => {
          e?.stopPropagation?.();
          e?.preventDefault?.();

          if (isEditing || disabled || disabledEvent) return;
          setCancelClone(ChiroUpJSON.clone(appointment));
          setIsEditing(true);
        }}
      >
        {isEditing || newAppointment ? (
          <form>
            {!appointment?.id && (
              <Autocomplete
                name="patientId"
                label="Patient *"
                value={value.patientId}
                placeholder="Type to search for a patient..."
                onChange={(val) => {
                  const fullPatient = flatPatients?.find(
                    (pt: Patient) => pt.ID === val,
                  );
                  if (fullPatient && !value.clinicianId) {
                    patchValue({
                      patientId: val,
                      clinicianId: fullPatient.primaryClinician,
                    });
                  } else if (fullPatient) {
                    patchValue({
                      patientId: val,
                    });
                  }
                }}
                options={patientOptions}
                limit={1}
                isFetching={isFetchingPatients}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                onChangeSearch={onSearch}
                searchTerm={searchQuery}
                errors={errors.fieldErrors?.patientId}
                addTitle="Create new patient..."
                add={newPatientOpen}
                autoFocus={!qs.parse(search)?.patient}
                serverSide
              />
            )}

            <div className="relative">
              {/* <label
                htmlFor="startDate"
                className="text-sm font-medium leading-5 text-gray-900 dark:text-darkGray-200 sm:mt-px sm:pt-2 flex flex-row justify-between"
              >
                <div></div>

              </label> */}
              {typeof value?.id === 'string' ? (
                <div className="text-xl font-sans font-bold">
                  <TimestampDisplay
                    ts={appointment?.startTime}
                    format={ChiroUpDayJsCommon.format.date}
                    errorValue={`- no date -`}
                  />
                </div>
              ) : (
                <fieldset className="mt-4 border rounded-lg shadow-inner pt-2 pb-6 px-6 bg-white">
                  <legend className="font-sans font-bold">
                    <div className="flex flex-row">
                      <div>Date</div>{' '}
                    </div>
                  </legend>
                  <div className="relative">
                    <div className="absolute top-2.5 right-9 z-100">
                      <TrivialTooltip
                        text={
                          dateInPast
                            ? 'The selected date is in the past.'
                            : undefined
                        }
                        iconColorClassName="text-orange-500"
                        iconSizeClassName="w-6 h-6"
                        // containerClassName="absolute top-2 right-9 z-100"
                        type={`warn`}
                      />{' '}
                    </div>

                    <Datepicker
                      value={{
                        startDate: dayjs(value?.startTime).format('YYYY-MM-DD'),
                        endDate: dayjs(value?.startTime).format('YYYY-MM-DD'),
                      }}
                      onChange={(val: any) => {
                        let firstStartTime: null | number = null;
                        const clone = ChiroUpJSON.clone(
                          value,
                        ) as AppointmentForUI;
                        clone?.slots?.forEach((slot) => {
                          const d = new Date(slot.startTime),
                            hhmmss = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
                            newStartTime = new Date(
                              `${val.startDate} ${hhmmss}`,
                            );
                          slot.startTime = newStartTime.getTime();
                          if (firstStartTime === null) {
                            firstStartTime = newStartTime.getTime();
                          }
                          // console.log({
                          //   val,
                          //   startTime: new Date(slot.startTime).toISOString(),
                          //   newStartTime: new Date(newStartTime).toISOString(),
                          //   hhmmss,
                          // });
                        });
                        // console.log({ Datepicker: val });
                        if ((clone as any)?.slots[0]?.startTime) {
                          clone.startTime = (clone as any)?.slots[0].startTime;
                        }
                        console.log({ st: clone.startTime });
                        setValue(clone);
                        // onChange('startTime')(
                        //   dayjs.tz(
                        //     `${val.startDate} ${getNearestTime(val.startDate)}`,
                        //     timezone,
                        //   ),
                        // );
                      }}
                      asSingle
                      useRange={false}
                      placeholder={'MM/DD/YYYY'}
                      displayFormat={'MM/DD/YYYY'}
                      primaryColor={'green'}
                      inputClassName={classNames(
                        typeof value?.id === 'string'
                          ? 'bg-gray-100 dark:bg-darkGray-600 dark:text-darkGray-200'
                          : 'bg-white dark:bg-darkGray-600 dark:text-darkGray-200',
                        'z-50 border w-full border-gray-300 shadow-sm rounded-md mt-1 focus:outline-none focus:ring-none focus:ring-primary-500 focus:border-transparent pl-2 ',
                      )}
                      disabled={typeof value?.id === 'string'}
                    />
                  </div>
                </fieldset>
              )}
            </div>
            <fieldset className="mt-6 border rounded-lg shadow-inner border-gray-300 pt-2 pb-6 px-6 bg-white space-y-2">
              <legend className="font-sans font-bold">Primary</legend>
              <Select
                name="clinicianId"
                className="col-span-2"
                label="Clinician"
                value={value.clinicianId}
                onChange={(e: any) => {
                  onChange('clinicianId')(e);
                }}
                errors={errors.fieldErrors?.clinicianId}
                options={primaryClinicianOptions}
                disabled={primaryClinicianOptions?.length === 1}
                limit={1}
                inlineSelect={true}
                inlineLabelWidth="w-1/4"
                inlineInputWidth="w-3/4"
              />
              <Select
                name="treatmentId"
                className="col-span-2"
                label="Treatment"
                value={value.treatmentId}
                onChange={(e: any) => {
                  if (primaryDisciplineOptions?.length === 1) {
                    onChange('disciplineId')(
                      primaryDisciplineOptions[0]?.value,
                    );
                  }
                  onChange('treatmentId')(e);
                }}
                errors={errors.fieldErrors?.treatmentId}
                options={primaryTreatmentOptions}
                disabled={primaryTreatmentOptions?.length === 1}
                limit={1}
                inlineSelect={true}
                inlineLabelWidth="w-1/4"
                inlineInputWidth="w-3/4"
              />
              <Select
                name="disciplineId"
                className="col-span-2"
                label="Discipline"
                value={value?.disciplineId}
                onChange={(e: any) => {
                  onChange('disciplineId')(e);
                }}
                errors={errors.fieldErrors?.disciplineId}
                options={primaryDisciplineOptions}
                disabled={primaryDisciplineOptions?.length === 1}
                limit={1}
                inlineSelect={true}
                inlineLabelWidth="w-1/4"
                inlineInputWidth="w-3/4"
              />
            </fieldset>

            {!!newAppointment && (
              <Select
                name="surveys"
                className="col-span-2"
                label="Request info"
                value={value.surveys}
                onChange={onChangeSurveys}
                errors={errors.fieldErrors?.surveys}
                options={surveyOptions}
                autocomplete
              />
            )}
            <div>
              <fieldset className="mt-6 border rounded-lg shadow-inner pt-2 pb-6 px-6 bg-white">
                <legend className="font-sans font-bold">Notes</legend>
                <Textarea
                  name="notes"
                  label=" "
                  value={value.notes}
                  onChange={onChange('notes')}
                  errors={errors.fieldErrors?.notes}
                />
              </fieldset>
            </div>

            <div className="flex flex-col space-x-2 justify-end">
              {/* <ul className="mt-9 list-none flex flex-row space-x-2">
                {value?.slots &&
                  value.slots.map((slot, ord) => (
                    <li
                      key={`top-${ord}`}
                      className="flex w-4 h-4 font-sans text-xs font-semibold border border-gray-600 p-3 rounded-full justify-center items-center hover:text-white hover:bg-primary-500 cursor-pointer"
                      onClick={(e: any) => {
                        console.log('onClick slot', ord);
                        e.stopPropagation();
                        console.log({ slotContext });
                        slotContext?.[ord]?.ref?.current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }}
                    >
                      {ord + 1}
                    </li>
                  ))}
              </ul> */}
              <div id="alertRef" className="w-full pt-6 pb-0">
                <AlertBlock
                  issues={businessRuleIssues}
                  closeCallback={clearBusinessRuleIssues}
                  emptyComponent={<div>&nbsp;</div>}
                  margin={'mt-1 my-4'}
                  extraClassName="shadow-inner"
                  intro={<> </>}
                />
              </div>

              <div className="flex flex-col space-x-4 w-full pr-4">
                <div
                  title="Click to see date-entry tips."
                  className="flex flex-row text-gray-400 space-x-1 w-14 cursor-pointer"
                  onClick={() => {
                    setShowTips(!showTips);
                  }}
                >
                  <div className="w-5">
                    <QuestionMarkCircleIcon className="w-5 h-5" />
                  </div>
                  <div className="-mt-0.5">Tips</div>
                </div>
                {showTips ? (
                  <div
                    id="tips-panel"
                    className="border border-gray-300 p-4 rounded-lg shadow-inner bg-white text-gray-600 relative"
                  >
                    <div className="absolute top-2 right-2">
                      <button
                        onClick={() => setShowTips(false)}
                        className="text-gray-400"
                      >
                        <XCircleIcon className="w-4 h-4" />
                      </button>
                    </div>
                    <ul className="list-disc text-sm font-sans px-6 py-4 space-y-2">
                      <li>
                        Click{' '}
                        <div
                          title="Plus icon."
                          className="w-5 inline-block text-primary-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.0"
                            stroke="currentColor"
                            // className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </div>{' '}
                        on the left below the number to add time slots.
                      </li>
                      <li>Only {maxSlots} may be added.</li>
                      <li>
                        When there is more than one slot the option to delete{' '}
                        <div className="inline-block text-primary-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </div>{' '}
                        time slots is available.
                      </li>
                      <li>The last time stop may not be deleted.</li>
                      <li>
                        Clicking a time-slot number scrolls the screen to that
                        time slot.
                      </li>
                      <li>
                        A clinician or a room must be selected for each slot.
                      </li>
                      <li>
                        Providing a clinician or room makes the time slot show
                        up on the schedule for that clinician or room.
                      </li>
                      <li>No other fields are optional.</li>
                      <li>
                        Clicking{' '}
                        <span className="py-0.5 px-2 shadow-md no-underline rounded-full bg-primary-500 text-white font-sans text-xs border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none">
                          Custom
                        </span>{' '}
                        will allow entering a custom duration.
                      </li>
                      <li>
                        Clicking{' '}
                        <span className="py-0.5 px-2 shadow-md no-underline rounded-full bg-primary-500 text-white font-sans text-xs border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none">
                          Preset
                        </span>{' '}
                        switches back to the preset duration options.
                      </li>
                      <li>
                        Time slots must be in order by time-of-day and may not
                        overlap. Time slot #2's start time must be after slot
                        #1's start time plus its duration.
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              {value?.slots &&
                value.slots.length > 0 &&
                value.slots.map((slot, ord) => (
                  <div key={`stop-${ord + 1}`} className="">
                    <ScheduleAppointmentFormSlot
                      key={`slot-${ord}`}
                      ord={ord}
                      startDate={value?.startTime?.valueOf()}
                      slot={slot}
                      options={slotOptions}
                      queryParams={queryParams}
                      context={slotContext}
                      setContext={setSlotContext}
                      deleteMySlot={deleteMySlotAndUpdateContext}
                      location={selectedLocationFull}
                      updateMySlot={updateMySlot}
                      disableButtonGroup={disableButtonGroup}
                      addSlotButton={
                        <IconButton
                          tooltipPlacement="left"
                          icon={
                            !atSlotLimit ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.0"
                                stroke="currentColor"
                                // className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                            ) : (
                              <div></div>
                            )
                          }
                          onClick={(event: any) => {
                            if (atSlotLimit) return;
                            event.stopPropagation();
                            addNewSlot();
                            markAsDirty();
                          }}
                          disabled={atSlotLimit}
                          className={classNames(
                            'w-8 h-8',
                            atSlotLimit ? 'text-gray-300' : 'text-primary-500',
                          )}
                        />
                      }
                    />
                  </div>
                ))}
            </div>

            <div className="flex-shrink-0 pl-4 py-5  mt-4">
              <div className="space-x-3 flex justify-end">
                {!newAppointment && (
                  <Button
                    text="Cancel"
                    onClick={(event) => {
                      setShowForm?.(false);
                      setEditType?.('');
                      setIsEditing(false);
                      setSlotContext({});
                      setValue(cancelClone);
                      setBusinessRuleIssues([]);
                      setLocalErrors('');
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    color={ButtonColors.plain}
                  />
                )}

                <Button
                  text="Save"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !okayToSave ||
                    !!localErrors?.length
                  }
                  loading={isSubmitting}
                  type="submit"
                  onClick={() => {
                    registerSubmit(onSubmit, {
                      onSuccess,
                      onFail,
                      options: {
                        throwOnNoResult: true,
                      },
                    })();
                  }}
                />
                <Button
                  text="Save & Notify"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !okayToSave ||
                    !!localErrors?.length
                  }
                  loading={isSubmitting}
                  type="button"
                  onClick={() => {
                    registerSubmit(onSubmitNotify, {
                      onSuccess,
                      onFail,
                      options: {
                        throwOnNoResult: true,
                      },
                    })();
                  }}
                />
              </div>
            </div>
          </form>
        ) : appointment ? (
          <div className="pb-5 pt-5 sm:pt-0">
            <dl className="space-y-8 sm:space-y-6">
              <div className="text-xl font-sans font-bold">
                <TimestampDisplay
                  ts={appointment?.startTime}
                  format={ChiroUpDayJsCommon.format.date}
                  errorValue={`- no date -`}
                />
              </div>
              {appointment?.notes ? (
                <div>
                  <fieldset className="mt-6 border rounded-lg shadow-inner border-gray-300 pt-2 pb-6 px-6 bg-inherit">
                    <legend className="font-sans font-bold">Notes</legend>
                    <div>{appointment?.notes}</div>
                  </fieldset>
                </div>
              ) : null}
              <div>
                {value?.slots && value.slots.length > 0 ? (
                  value.slots.map((slot, ord) => (
                    <div key={`stop-${ord + 1}`} className="">
                      <ScheduleAppointmentFormSlot
                        key={`slot-${ord}`}
                        ord={ord}
                        startDate={value?.startTime?.valueOf()}
                        slot={slot}
                        options={slotOptions}
                        queryParams={queryParams}
                        disableButtonGroup={disableButtonGroup}
                        context={slotContext}
                        setContext={setSlotContext}
                        deleteMySlot={deleteMySlotAndUpdateContext}
                        location={selectedLocationFull}
                        updateMySlot={updateMySlot}
                        readonly={true}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                        Clinician
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                        <p>{appointment?.displayValues?.clinicianName}</p>
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                        Treatment
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                        {appointment?.displayValues?.treatmentName}
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-gray-300">
                        Duration
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-gray-300">
                        {durationDisplay(appointment?.duration)}
                      </dd>
                    </div>
                  </>
                )}
              </div>
            </dl>
            {/* <AppointmentServices
              appointment={value}
              patchValue={patchValue}
              setOkayToSave={setOkayToSave}
              readonly={true}
            /> */}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
      <div className="text-right font-light text-xs text-black">
        {disabledEvent ? ChiroUpAppointmentCommon.cannotModify : ''}
      </div>
      <PatientCreateModal
        isOpen={newPatientModalState === OpenClosedStates.Open}
        close={newPatientClose}
        lastName={``}
        stayOnRoute={true}
        closeSearch={() => {}}
        appointmentPanel
      />
      <ConfirmModal
        isOpen={!!doubleBookErrorMessage?.length}
        confirm={doubleBookConfirm}
        close={() => {
          setDoubleBookErrorMessage?.([]);
        }}
        loading={doubleBooking}
        title={'Room is already booked'}
        confirmText={appointment?.id ? 'Update appointment' : 'Book room'}
        bigger
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-8">
          {doubleBookErrorMessage?.map((msg: RoomStatus, index: number) => (
            <div
              key={`${msg.startTime} - ${index}`}
              className="p-4 rounded-md bg-white border border-gray-200 shadow-md"
            >
              <p>
                <span className="font-semibold">Patient:</span>{' '}
                {msg?.patientName}
              </p>
              <p>
                <span className="font-semibold">Clinician:</span>{' '}
                {msg?.clinicianName}
              </p>
              <p>
                <span className="font-semibold">Treatment:</span>{' '}
                {msg?.treatmentName}
              </p>
              <p>
                <span className="font-semibold">Room:</span> {msg?.roomName}
              </p>
              <p>
                <span className="font-semibold">Booked:</span> {msg?.startTime}{' '}
                - {msg?.endTime}
              </p>
            </div>
          ))}
        </div>
      </ConfirmModal>
      {/* <pre>
        {ChiroUpJSON.pretty({
          topDisciplineId: value?.disciplineId,
          firstSlot: value?.slots?.[0],
          // isDirty,
          // isSubmitting,
          // okayToSave,
          // localErrors,
          // treatmentHash,
          // slotOptions,
          // disciplineOptions,
          primaryDisciplineOptions,
          // cancelClone,
          // errors,
        })}
      </pre> */}
      <MakeBrowserWait isWaiting={isSubmitting} />
    </div>
  );
};

export default ScheduleAppointmentForm;
